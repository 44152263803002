import { styled } from "@sync/core";
import { CardProps } from "./props";
import { motion } from "framer-motion";
import { keyframes } from "@emotion/react";

export const Container = styled("div")`
  width: 100vw;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  gap: 24px;
  padding: 24px;
  height: 100vh;
  max-height: 100vh;
  background: hsla(239, 94%, 14%, 1);

  background: linear-gradient(
    90deg,
    hsla(239, 94%, 14%, 1) 0%,
    hsla(190, 68%, 50%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(239, 94%, 14%, 1) 0%,
    hsla(190, 68%, 50%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(239, 94%, 14%, 1) 0%,
    hsla(190, 68%, 50%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#020344", endColorstr="#28B8D5", GradientType=1 );
  position: relative;

  & .card {
    width: 100%;
    max-width: 450px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;

const animate = keyframes`
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: .2;
        border-radius: 50%;
    }
`;

export const AnimationWrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const Circle = styled("li")<any>`
  position: absolute;
  display: block;
  list-style: none;
  width: ${({ width }) => width || "20px"};
  height: ${({ height }) => height || "20px"};
  background: rgba(255, 255, 255, 0.2);
  animation: ${animate} 25s linear infinite;
  bottom: -150px;

  &:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }

  &:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  &:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  &:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  &:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  &:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  &:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  &:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  &:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  &:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }

  &:nth-child(11) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }

  &:nth-child(12) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
`;

export const IllustrationContainer = styled("div")`
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Illustration = styled(motion.img)`
  width: 100%;
  max-width: 620px;
`;

export const Card = styled("div")<CardProps>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background.paper} !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  transition: all 0.5s;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  position: relative;
  padding: 24px;
  margin: 0 auto;
  max-height: 80vh;
`;

export const LogoContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-block: 48px;
`;

export const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-block: 36px;
  overflow: auto;

  & .title {
    width: 100%;
    text-align: center;
    font-weight: 900;
    font-size: 36px;
    color: ${({ theme }) => theme.palette.text.primary};

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  & .description {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: ${({ theme }) => theme.palette.text.secondary};

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export const LinkWrapper = styled("div")`
  width: 100%;
  position: absolute;
  bottom: 8px;
  left: 25%;
  transform: translate(-50%, -8px);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
`;

export const LinkComponent = styled("a")`
  all: unset;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: pointer;
  font-weight: 600;

  & strong {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:hover {
    & strong {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
