import { useState } from "react";
import { useForm } from "react-hook-form";
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import { Iconify, Button } from "@sync/core";
import { useLogin } from "src/hooks/auth";
import { LoginRequestDto } from "src/hooks/auth/mutations/useLogin/props";
import Head from "next/head";
import { useAuthStore } from "src/store/authStore";
import { AnimatePresence, motion } from "framer-motion";
import { varFade } from "src/components/animate";
import Link from "next/link";

export default function AuthLoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const { showResetPassword } = useAuthStore();

  const { mutate: login, isLoading } = useLogin();

  const methods = useForm<LoginRequestDto>({});

  const { handleSubmit } = methods;

  const onSubmit = async (data: LoginRequestDto) => {
    login({
      ...data,
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Head>
        <title>Login | Sync</title>
      </Head>
      <AnimatePresence
        key={`show-passowrd-${showResetPassword}`}
        exitBeforeEnter
      >
        <motion.div {...varFade().inUp}>
          <Stack spacing={3}>
            <RHFTextField name="email" label="E-mail" />

            <RHFTextField
              name="password"
              label="Senha"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack alignItems="flex-end" sx={{ my: 2 }}>
            <Link href="/auth/recuperar-senha">
              <Button
                size="small"
                variant="text"
                sx={{ textTransform: "lowercase !important" }}
              >
                Esqueceu sua senha?
              </Button>
            </Link>
          </Stack>
        </motion.div>
      </AnimatePresence>
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        sx={{
          bgcolor: "text.primary",
          color: (theme) =>
            theme.palette.mode === "light" ? "common.white" : "grey.800",
          "&:hover": {
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
          },
        }}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
