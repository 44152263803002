/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Portals {
  WEB_MOTORS_PORTAL_NAME = "WebMotorsPortalName",
  AUTOLINE_PORTAL_NAME = "AutolinePortalName",
  COMPRE_CAR_PORTAL_NAME = "CompreCarPortalName",
  FACEBOOK_PORTAL_NAME = "FacebookPortalName",
  I_CARROS_PORTAL_NAME = "iCarrosPortalName",
  MERCADO_LIVRE_PORTAL_NAME = "MercadoLivrePortalName",
  MEU_CARRO_NOVO_PORTAL_NAME = "MeuCarroNovoPortalName",
  MOBI_AUTO_PORTAL_NAME = "MobiAutoPortalName",
  OLX_PORTAL_NAME = "OlxPortalName",
  RESOURCES_PORTAL_NAME = "ResourcesPortalName",
  MOTOR_ME_PORTAL_NAME = "MotorMePortalName",
  USADOSBR_PORTAL_NAME = "UsadosbrPortalName",
  SYNC_CMS_PORTAL_NAME = "SyncCmsPortalName",
  CARRO_SP = "CarroSP",
  CHAVES_NA_MAO = "ChavesNaMao",
  PLATFORMS = "Platforms",
  SO_CARRAO = "SoCarrao",
  REPASSE_AUTOS = "RepasseAutos",
  YOUTUBE = "Youtube",
  COCKPIT = "Cockpit",
  NA_PISTA_LEADS = "NaPistaLeads",
  SALAO_DO_CARRO = "SalaoDoCarro",
  COMPRE_CAR_PORTAL_LEADS = "CompreCarPortalLeads",
  CLICK_AUTOS = "ClickAutos",
}
